<template>
    <Form
        class="newsletter"
        :class="[{ loading: isLoading }, style]"
        :validation-schema="schema"
        enctype="multipart/form-data"
        method="POST"
        accept-charset="UTF-8"
        autocomplete="off"
        @submit="onSubmitFrm"
        @invalid-submit="onInvalidSubmitFrm"
    >
        <div class="fied-row">
            <div class="field-box">
                <Field
                    id="emailadres"
                    class="field"
                    type="email"
                    name="emailadres"
                    autocomplete="off"
                    :placeholder="$t('forms.newsletter.email_placeholder')"
                    @change="(e) => formObjectOnChange(e)"
                />
                <UiPButtonSubmit
                    :btn-type="'submit'"
                    :btn-title="submitBtnTitle"
                    :loading="isLoading"
                    :btn-style="`${style == 'dark' ? 'light' : 'dark'} linked`"
                />
            </div>
        </div>
        <ErrorMessage name="emailadres" />
    </Form>
</template>

<script setup>
    import { Form, Field } from "vee-validate";
    import * as yup from "yup";

    const props = defineProps({
        fieldId: {
            type: String,
            default: ""
        },
        style: {
            type: String,
            default: "light"
        }
    });

    const { t } = useI18n();
    const isLoading = ref(false);
    const startLoading = ref(0);
    const schema = yup.object({
        emailadres: yup
            .string()
            .required(t("forms.newsletter.errors.email_required"))
            .email(t("forms.newsletter.errors.email_validate"))
    });

    const onSubmitFrm = async (values, actions) => {
        isLoading.value = true;
        startLoading.value = 1;

        try {
            const response = await $fetch("/api/mailer", {
                method: "POST",
                body: values
            });

            if (response?.id) startLoading.value = 2;
        } catch (error) {
            console.error(error);
        } finally {
            isLoading.value = false;

            setTimeout(() => {
                startLoading.value = 0;
                actions.resetForm();
            }, 1000);
        }
    };

    const onInvalidSubmitFrm = async ({ values, errors, results }) => {
        const errorsKeys = Object.keys(errors).reverse();

        if (errorsKeys.length) {
            document.getElementById(errorsKeys[0]).focus();
        }
    };

    const formObjectOnChange = (e) =>
        e.target.value != "" ? e.target.classList.add("hasValue") : e.target.classList.remove("hasValue");

    const submitBtnTitle = computed(() => {
        if (startLoading.value == 1) {
            return t("forms.newsletter.submit_button_sending");
        } else if (startLoading.value == 2) {
            return t("forms.newsletter.submit_button_done");
        }

        return t("forms.newsletter.submit_button_default");
    });
</script>
